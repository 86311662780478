import { fontFamilyName, secondaryColor } from "../../../styles/mui/theme";

export const muiStylesSidebar = {
  text: {
    fontFamily: fontFamilyName,
},
  icon: {
    fontFamily: fontFamilyName,
    color: secondaryColor,
  },
  bottomItems: {
    fontFamily: fontFamilyName,
    bottom: 0,
  },
  drawer: {
    fontFamily: fontFamilyName,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  },
};
