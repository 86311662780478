import * as styles from "../layout.module.scss";
import React, { FC, ReactNode, useEffect, useState } from "react";
import OptimisLogo from "../../../images/svg/logo/optimis-logo.svg";
import Sidebar from "../Sidebar";
import { Box } from "@mui/material";
import MainTopbar from "../MainTopbar";
import { muiStylesStandardLayout } from "../muiStylesLayout";
import { useIsMobile } from "../../../utils/hooks";
import OptimisDiv100vh from "../Div100vh";
import SidebarAdmin from "../SidebarAdmin";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import { setupRefreshHandler } from '../../../utils/paths';

interface Props {
  children: ReactNode;
  hideTopbar?: boolean;
  showSearch?: boolean;
  showHelp?: boolean;
  adminPanel?: boolean;
}

const StandardLayout: FC<Props> = ({
  children,
  hideTopbar,
  showHelp = false,
  adminPanel = false
}) => {
  const dispatch = useDispatch();
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const isMobile = useIsMobile();
  const handleToggleDrawer = () => setIsSideBarOpen(!isSideBarOpen);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
        handleLogout();
      }
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (event.type === "beforeunload" && (event as any).key === "F5" || ((event as any).ctrlKey && (event as any).key === "r")) {
        handleLogout();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => setIsSideBarOpen(false), [isMobile]);

  useEffect(() => {
    const cleanup = setupRefreshHandler(handleLogout);

    return () => {
      cleanup();
    };
  }, []);
  return (
    <OptimisDiv100vh>
      <Box sx={muiStylesStandardLayout.root}>
        {adminPanel ? <SidebarAdmin open={isSideBarOpen} toggleDrawer={handleToggleDrawer} /> :
          <Sidebar open={isSideBarOpen} toggleDrawer={handleToggleDrawer} />}
        <Box component="main" sx={muiStylesStandardLayout.mainContainer}>
          {(isMobile || !hideTopbar) && (
            <div className={styles.topbar}>
              <OptimisLogo className={styles.topbarLogoOptimis} />
              <div className={styles.topbarContentDashboard}>
                <MainTopbar
                  adminPanel={adminPanel}
                  handleDrawerToggle={handleToggleDrawer}
                  showHelp={showHelp}
                />
              </div>
            </div>
          )}
          <div className={!isMobile ? styles.content : styles.contentMobile}>{children}</div>
        </Box>
      </Box>
    </OptimisDiv100vh>
  );
};

export default StandardLayout;
