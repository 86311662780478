// extracted by mini-css-extract-plugin
export var h1Typography = "sidebar-module--h1-typography--HPK7d";
export var h2Typography = "sidebar-module--h2-typography--IZuSt";
export var h2TypographySecond = "sidebar-module--h2-typography-second--ZrQPa";
export var h3Typography = "sidebar-module--h3-typography--FDEDX";
export var h3TypographyError = "sidebar-module--h3-typography-error--K1R0c";
export var h4Typography = "sidebar-module--h4-typography--zH3cR";
export var pTypography = "sidebar-module--p-typography--Gw6Z+";
export var smallPTypography = "sidebar-module--small-p-typography--HE33B";
export var sidebarTypography = "sidebar-module--sidebar-typography--VkiIR";
export var sidebarContainer = "sidebar-module--sidebar-container--jAh7P";
export var errorTypography = "sidebar-module--error-typography--Ef791";
export var captionTypography = "sidebar-module--caption-typography--i61LV";
export var authMessageLabelTypography = "sidebar-module--auth-message-label-typography--WndFH";
export var authMessageTypography = "sidebar-module--auth-message-typography--a6ser";
export var versionInfoTypography = "sidebar-module--version-info-typography--V1zw1";
export var itemHidden = "sidebar-module--item-hidden--BYq9h";
export var ___gatsby = "sidebar-module--___gatsby--uc+8p";
export var gatsbyFocusWrapper = "sidebar-module--gatsby-focus-wrapper--tg+wd";
export var logoutContainer = "sidebar-module--logout-container--YFtXW";