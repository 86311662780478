import { fontFamilyName } from "../../styles/mui/theme";

export const muiStylesStandardLayout = {
  root: {
    fontFamily: fontFamilyName,
    display: "flex",
    height: "100%",
  },
  mainContainer: {
    fontFamily: fontFamilyName,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
};
