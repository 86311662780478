// extracted by mini-css-extract-plugin
export var h1Typography = "sidebar-module--h1-typography--m006I";
export var h2Typography = "sidebar-module--h2-typography--K7gGP";
export var h2TypographySecond = "sidebar-module--h2-typography-second--NT8H8";
export var h3Typography = "sidebar-module--h3-typography--edkoL";
export var h3TypographyError = "sidebar-module--h3-typography-error--64E3b";
export var h4Typography = "sidebar-module--h4-typography--KNbeV";
export var pTypography = "sidebar-module--p-typography--p74by";
export var smallPTypography = "sidebar-module--small-p-typography--Szqw0";
export var sidebarTypography = "sidebar-module--sidebar-typography--ETAhJ";
export var sidebarContainer = "sidebar-module--sidebar-container--2qRFi";
export var errorTypography = "sidebar-module--error-typography--y-SZj";
export var captionTypography = "sidebar-module--caption-typography--VdpDS";
export var authMessageLabelTypography = "sidebar-module--auth-message-label-typography---yNnN";
export var authMessageTypography = "sidebar-module--auth-message-typography--HNfYR";
export var versionInfoTypography = "sidebar-module--version-info-typography--MEJUT";
export var itemHidden = "sidebar-module--item-hidden--4hoVW";
export var ___gatsby = "sidebar-module--___gatsby--tQoYV";
export var gatsbyFocusWrapper = "sidebar-module--gatsby-focus-wrapper--xJN0m";
export var logoutContainer = "sidebar-module--logout-container--74-NZ";