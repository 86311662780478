import * as styles from "./sidebar.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  CSSObject,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useTheme,
} from "@mui/material";
import {
  sideBarListUpperItems,
  sideBarListBottomItems,
  drawerWidth,
} from "../utlis";
import { muiStylesSidebar } from "./muiStylesSidebar";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import { useIsMobile } from "../../../utils/hooks";

interface Props {
  open: boolean;
  toggleDrawer: () => void;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const closedMixinMobile = (theme: Theme): CSSObject => ({
  overflowX: "hidden",
  width: "10px",
});

const Sidebar: FC<Props> = ({ open, toggleDrawer }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleLogout = () => {
    dispatch(logout());
  };

  const drawerItems = () => {
    return (
      <div className={styles.sidebarContainer}>
        <div>
          <ListItemButton>
            <ListItemIcon onClick={toggleDrawer} sx={muiStylesSidebar.icon}>
              {isMobile ? <CloseIcon fontSize="large" /> : <MenuIcon />}
            </ListItemIcon>
          </ListItemButton>
          <List>
            {sideBarListUpperItems.map((item) =>
            (item.name === "search" && !isMobile ? null : (
              <ListItemButton
                key={`${item.name}-item-upper-sidebar`}
                onClick={item.event}
              >
                <ListItemIcon sx={muiStylesSidebar.icon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={I18n.t(item.text)}
                  sx={muiStylesSidebar.text}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    style: {
                      whiteSpace: 'initial',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '1rem',
                    }
                  }}
                />
              </ListItemButton>
            ))
            )}
          </List>
        </div>
        <List sx={muiStylesSidebar.bottomItems}>
          {sideBarListBottomItems.map((item) => {
            return (
              <ListItemButton
                key={`${item.name}-item-bottom-sidebar`}
                onClick={item.name !== "logout" ? item.event : handleLogout}
              >
                <ListItemIcon sx={muiStylesSidebar.icon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText sx={muiStylesSidebar.text} primary={I18n.t(item.text)} />
              </ListItemButton>
            );
          })}
        </List>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <Drawer anchor="right" open={open} onClose={toggleDrawer}
          variant="persistent"
          sx={[
            muiStylesSidebar.drawer,
            {
              ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
              }),
              ...(!open && {
                ...closedMixinMobile(theme),
                "& .MuiDrawer-paper": closedMixinMobile(theme),
              }),
            },
          ]}

        >
          {drawerItems()}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          variant="permanent"
          sx={[
            muiStylesSidebar.drawer,
            {
              ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
              }),
              ...(!open && {
                ...closedMixin(theme),
                "& .MuiDrawer-paper": closedMixin(theme),
              }),
            },
          ]}
        >
          {drawerItems()}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
