import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import {
  handleNavigateToPage,
  userSettingsPath,
  helpdeskPath,
  searchPath,
  directoriesPath,
  dashboardPath,
  dashboardAdminPath,
  adminSettingsPath,
  searchAdminPath,
} from "../../utils/paths";

export const drawerWidth = 240;

export const sideBarListUpperItems = [
  {
    name: "dashboard",
    icon: <HomeIcon />,
    text: "Common.menu.dashboard",
    event: () => handleNavigateToPage(dashboardPath),
  },
  {
    name: "folders",
    icon: <FolderIcon />,
    text: "Common.menu.folders",
    event: () => handleNavigateToPage(directoriesPath),
  },
];

export const sideBarAdminListUpperItems = [
  {
    name: "psychlabs",
    icon: <FolderIcon />,
    text: "Common.menu.psychlabs",
    event: () => handleNavigateToPage(dashboardAdminPath),
  },
];

export const sideBarListBottomItems = [
  {
    name: "search",
    icon: <SearchIcon />,
    text: "Common.menu.search",
    event: () => handleNavigateToPage(searchPath),
  },
  {
    name: "help",
    icon: <HelpIcon />,
    text: "Common.menu.contact",
    event: () => handleNavigateToPage(helpdeskPath),
  },
  {
    name: "settings",
    icon: <SettingsIcon />,
    text: "Common.menu.settings",
    event: () => handleNavigateToPage(userSettingsPath),
  },
  {
    name: "logout",
    icon: <LogoutIcon />,
    text: "Common.menu.logout",
  },
];

export const sideBarAdminListBottomItems = [
  {
    name: "search",
    icon: <SearchIcon />,
    text: "Common.menu.search",
    event: () => handleNavigateToPage(searchAdminPath),
  },
  {
    name: "settings",
    icon: <SettingsIcon />,
    text: "Common.menu.settings",
    event: () => handleNavigateToPage(adminSettingsPath),
  },
  {
    name: "logout",
    icon: <LogoutIcon />,
    text: "Common.menu.logout",
  },
];
